<template>
  <div>
    <div class="files-list mt-3">
      <file-input
        @input="(val) => handleFile(val, fileCallback)"
        :loading="fileLoading"
        :loaded="model.length"
        ><template v-for="(_, name) in $scopedSlots" #[name]="data"
          ><slot :name="name" v-bind="data"></slot></template
      ></file-input>
    </div>
    <div :key="cropped[cropped.length - 1]" class="">
      <shuffle-list-transition>
        <div
          class="my-3"
          style="border: 1px solid #d5dfe4; border-radius: 5px"
          :key="screenshot.id"
          v-for="(screenshot, i) in model"
        >
          <div
            style="height: 42px"
            class="p-1 px-3 d-flex justify-content-between align-items-center"
          >
            <div style="width: 89%" class="d-flex flex-column justify-content-center">
              <p
                class="ws-nowrap"
                style="
                  text-overflow: ellipsis;
                  overflow: hidden;
                  line-height: 15.1px;
                  font-size: 13px;
                "
              >
                {{ screenshot.filename || screenshot.image || screenshot.screen }}
              </p>
              <p
                class="fs-12 lh16d2"
                v-if="cropped.includes(screenshot.filename)"
                style="color: red"
              >
                Есть подозрение, что скриншот обрезан
              </p>
            </div>
            <img
              style="width: 16px; height: 16px"
              src="@main/assets/img/svg/gray-cross.svg"
              @click="model.splice(i, 1)"
              alt=""
            />
          </div>
        </div>
      </shuffle-list-transition>
    </div>
  </div>
</template>

<script>
import apiService from '@main/api/index';
import { mapMutations } from 'vuex';
import FileInput from '../../forms/FileInput.vue';
import ShuffleListTransition from '../transitions/ShuffleListTransition.vue';

export default {
  components: { FileInput, ShuffleListTransition },
  data: () => ({ fileLoading: null, cropped: [] }),
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: [Object, Array],
    uploadUrl: String,
    detectCropped: Boolean,
  },
  methods: {
    handleFile(files, callback) {
      const formData = new FormData();
      for (let k = 0; k < files.length; k += 1) formData.append('files[]', files[k]);
      apiService
        .post(this.uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // eslint-disable-next-line func-names
          onUploadProgress: function (event) {
            // eslint-disable-next-line radix
            this.fileLoading = parseInt(Math.round((event.loaded / event.total) * 100));
          }.bind(this),
        })
        .then((res) => {
          callback(res, files);
        })
        .catch((error) => {
          this.fileLoading = false;
          this.showMessage({
            title: `Ошибка при загрузке файлов ${error || null}`,
          });
        })
        .finally(() => {
          this.fileLoading = false;
        });
    },
    fileCallback(res, files) {
      try {
        for (let k = 0; k < files.length; k += 1) {
          res[k].filename = files[k].name;
          if (this.detectCropped) {
            const reader = new FileReader();
            reader.readAsDataURL(files[k]);
            reader.onload = (e) => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = (ev) => {
                const asRatio = ev.path[0].naturalHeight / ev.path[0].naturalWidth;
                // eslint-disable-next-line yoda
                if (asRatio > 0.8 && asRatio < 1.2) {
                  this.cropped.push(files[k].name);
                }
              };
            };
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit('input', this.model.concat(res));
      }
    },
  },
};
</script>
