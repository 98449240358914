<template>
  <div>
    <div class="settings__common--checkboxes">
      <div
        v-for="(block, idx) in legalStatusList"
        :key="`${idx}-blcklglSttsLst`"
        class="settings__common--checkboxes--block"
      >
        <label v-for="(i, idx) in block" :key="`${idx}-blckLglSttsaWChck`" class="legal-status">
          <input
            type="radio"
            :value="i.value"
            v-model="model.legal_status"
            class="legal-status--radio status__check"
          />
          <span class="legal-status__block"></span>
          <span class="legal-status__text">{{ i.display_name }}</span>
        </label>
      </div>
    </div>
    <template v-if="legalStatusMapping[model.legal_status]">
      <div
        class="settings__common--opac"
        style="margin-top: 14px; margin-bottom: 8px"
        title="Недоступен для физ. лиц"
      >
        {{ legalStatusMapping[model.legal_status].name }}
      </div>
      <b-input
        :disabled="model.legal_status === 1"
        type="number"
        name="cipher"
        class="settings__input ph-36"
        :value="model[legalStatusMapping[model.legal_status].model]"
        @change="
          (val) => {
            model[legalStatusMapping[model.legal_status].model] = val;
          }
        "
        id="cipher"
      />
      <div class="settings__common--opac" style="margin-top: 8px">
        {{ legalStatusMapping[model.legal_status].name }}
        необходим для подтверждения вашего юридического статуса и не будет указан на сайте
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: [Object, Array],
      required: true,
    },
    cols: {
      type: Number,
      default: 2,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  model: {
    event: 'input',
    prop: 'model',
  },
  data: () => ({
    legalStatusMapping: {
      2: {
        name: 'ИНН',
        model: 'inn',
      },
      3: {
        name: 'ОГРНИП',
        model: 'ogrnip',
      },
      4: {
        name: 'ОГРН',
        model: 'ogrn',
      },
    },
  }),
  computed: {
    legalStatusList() {
      const chunkArray = (myArray, chunkSize) => {
        let index = 0;
        const arrayLength = myArray.length;
        const tempArray = [];
        let myChunk;

        for (index = 0; index < arrayLength; index += chunkSize) {
          myChunk = myArray.slice(index, index + chunkSize);
          tempArray.push(myChunk);
        }

        return tempArray;
      };
      return chunkArray(this.options, 2);
    },
  },
};
</script>

<style lang="scss" scoped></style>
