<template>
  <div v-if="array">
    <slot name="upper" v-bind="{ increment, decompose }"></slot>
    <component
      @copy="increment"
      @delete="decompose(obj.uid)"
      :ref="obj.uid"
      :key="`${obj.uid}-${idx}-prtpFrmMn`"
      v-for="(obj, idx) in array"
      v-model="array[getIdxById(obj.uid)]"
      v-bind="{ ...formComponentAttrs, idxnum: idx, len: array.length }"
      :is="formComponent || MetaDataForm"
    ></component>
    <slot name="lower" v-bind="{ increment, decompose }"></slot>
  </div>
</template>

<script>
import Vue from 'vue';
import MetaDataForm from '../forms/MetaDataForm.vue';

export default {
  name: 'nested-list-form-container',
  data: () => ({ MetaDataForm }),
  model: {
    prop: 'array',
    event: 'input',
  },
  props: {
    array: {
      type: [Object, Array],
      required: true,
    },
    templateObject: {
      type: Object,
      required: true,
    },
    formComponent: { type: [String, Object, Vue], default: MetaDataForm },
    formComponentAttrs: {
      type: Object,
    },
    lengthLimit: { type: Number },
    initialCount: { type: Number },
  },
  methods: {
    increment(val) {
      if (val && val.id) {
        delete val.id;
      }
      if (this.array.length >= this.lengthLimit) {
        return;
      }
      const model = this.lodash.cloneDeep(val || this.templateObject);
      model.uid = this.getUid();
      this.array.push(model);
    },
    decompose(uid) {
      this.array.splice(this.getIdxById(uid), 1);
    },
    getIdxById(uid) {
      return this.array.indexOf(this.array.filter((val) => val.uid === uid)[0]);
    },
    getUid() {
      return this.array.length + Math.floor(Math.random() * 100000);
    },
    validate() {
      const refArr = Object.entries(this.$refs);
      refArr.forEach((item) => {
        const ref = item[1][0];
        console.log(ref);
        if (ref) {
          ref.$v.$touch();
        }
      });
      const valid = refArr.every((item) => {
        const ref = item[1][0];
        if (!ref) {
          return true;
        }
        return !ref.$v.$anyError;
      });
      return valid;
    },
  },
  mounted() {
    console.log(this.array);
    if (!this.array.length) {
      for (let i = 0; i <= (this.initialCount || 1); i += 1) {
        this.increment();
      }
    } else if (this.array) {
      this.array.forEach((el) => {
        el.uid = this.getUid();
      });
    }
  },
};
</script>
