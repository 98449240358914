<template>
  <b-form v-bind="$attrs">
    <template v-for="(row, idx) in formGrid">
      <slot :name="`outRow${idx}`"></slot>
      <slot :name="`outRow(${row[0][0]})`"></slot>
      <b-row :key="idx" v-if="!(row.length === 1 && excludedFields.includes(row[0][0]))">
        <slot :name="`inRow${idx}`"></slot>
        <template v-for="field in row">
          <b-col v-bind="field[1].attrs" :key="field[0]">
            <slot :name="`col${idx}`"></slot>
            <template v-if="!$scopedSlots[field[0]]">
              <b-form-group :style="formGroupStyle">
                <label
                  :class="{
                    required__star: field[1].required && !disableRequiredStar,
                    [labelStyle]: true,
                  }"
                  class="fs-16"
                  v-if="!$scopedSlots[`${field[0]}_title`] && !field[1].nolabel && field[1].label"
                  >{{
                    field[1].label +
                    (field[1].label.endsWith('?') || field[1].label.endsWith('.') || disableEndDots
                      ? ''
                      : endCharacter || ': ')
                  }}</label
                >
                <slot v-bind="{ field }" :name="`${field[0]}_title`"></slot>
                <screenshot-loader
                  v-if="field[1].api_widget === 'screenshots'"
                  v-model="model[field[0]]"
                  :uploadUrl="field[1].upload_path"
                ></screenshot-loader>
                <template v-else-if="field[1].api_widget === 'screenshot'">
                  <div style="margin-top: 24px !important"></div>
                  <SingleFileInput
                    :buttonStyle="'height: 40px !important'"
                    :uploadUrl="field[1].upload_path"
                    :handleUpload="true"
                    :class="{ error: $v.model[field[0]].$anyError }"
                    v-model="$v.model[field[0]].$model"
                /></template>
                <div class="hovering__pad" v-else-if="field[1].child && field[1].child.children">
                  <slot :name="`${field[0]}nestedTop`"></slot>
                  <nested-list-form-container
                    v-model="model[field[0]]"
                    :formComponentAttrs="{ metadata: field[1].child.children }"
                    :templateObject="parseObject(field[1].child.children)"
                  >
                  </nested-list-form-container>
                  <slot :name="`${field[0]}nestedBottom`"></slot>
                </div>
                <div class="hovering__pad" v-else-if="field[1].children">
                  <meta-data-form
                    v-model="model[field[0]]"
                    :metadata="field[1].children"
                  ></meta-data-form>
                </div>
                <div
                  v-else-if="field[1].api_widget === 'radio_blocks'"
                  class="align-items-start d-flex flex-wrap justify-content-between"
                >
                  <template
                    v-for="option in field[1].type === 'boolean'
                      ? [
                          { value: true, display_name: 'Да' },
                          { value: false, display_name: 'Нет' },
                        ]
                      : field[1].choices"
                  >
                    <LeftPointedRadioButton
                      :key="`${option.value}-o124kirTpFrm43s`"
                      :variant="
                        !(typeof model[field[0]] === 'object') && option.value === model[field[0]]
                          ? 'primary'
                          : 'outline-default'
                      "
                      @click="
                        () => {
                          model[field[0]] = option.value;
                        }
                      "
                      :class="{ error: $v.model[field[0]].$anyError }"
                      class="mr-1 mt-2 fmt-rd ph-36 text-left"
                      style="line-height: 13px"
                    >
                      {{ option.display_name }}
                    </LeftPointedRadioButton>
                  </template>
                </div>
                <div v-else-if="field[1].api_widget === 'radio_blocks_stacked'">
                  <template v-for="option in field[1].choices">
                    <LeftPointedRadioButton
                      :key="`${option.value}-o124kirTpFrm43s`"
                      :variant="option.value === model[field[0]] ? 'primary' : 'outline-default'"
                      @click="
                        () => {
                          model[field[0]] = option.value;
                        }
                      "
                      :class="{ error: $v.model[field[0]].$anyError }"
                      class="mt-2 w-100 ph-36 text-left"
                      style="line-height: 13px"
                    >
                      {{ option.display_name }}
                    </LeftPointedRadioButton>
                  </template>
                </div>
                <div
                  v-else-if="field[1].api_widget === 'textarea'"
                  class="input__symbol--count position-relative mb-5"
                  :symbolCount="(model[field[0]] || '').length"
                  :symbolLimit="field[1].max_length"
                >
                  <b-form-textarea
                    :maxlength="field[1].max_length"
                    v-model="$v.model[field[0]].$model"
                    :class="{ error: $v.model[field[0]].$anyError }"
                    :style="`min-height: ${
                      field[1].max_length - (field[1].max_length / 100) * 37
                    }px`"
                  ></b-form-textarea>
                </div>
                <legal-status-radio-group
                  v-else-if="field[1].api_widget === 'legal_status'"
                  :options="field[1].choices"
                  v-model="model"
                ></legal-status-radio-group>
                <template v-else-if="field[1].type === 'boolean'">
                  <b-checkbox
                    v-model="$v.model[field[0]].$model"
                    :class="{ error: $v.model[field[0]].$anyError }"
                    >{{ field[1].label }}</b-checkbox
                  >
                </template>
                <b-form-datepicker
                  v-else-if="field[1].type === 'date'"
                  locale="ru"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  placeholder="Дата"
                  v-model="$v.model[field[0]].$model"
                  :class="{ error: $v.model[field[0]].$anyError }"
                ></b-form-datepicker>
                <b-form-timepicker
                  v-else-if="field[1].type === 'time'"
                  placeholder="Время"
                  locale="ru"
                  v-model="$v.model[field[0]].$model"
                  :class="{ error: $v.model[field[0]].$anyError }"
                ></b-form-timepicker>
                <list-input
                  v-else-if="field[1].type === 'array'"
                  placeholder="Введите значения через запятую"
                  v-model="$v.model[field[0]].$model"
                  :class="{ error: $v.model[field[0]].$anyError }"
                ></list-input>
                <b-form-input
                  v-else-if="field[1].api_widget === 'range'"
                  :class="{ error: $v.model[field[0]].$anyError }"
                  v-model="$v.model[field[0]].$model"
                  :step="field[1].step"
                  :min="field[1].min_value"
                  :max="field[1].max_value"
                  type="range"
                ></b-form-input>
                <b-form-input
                  :type="inputTypeMapping[field[1].type]"
                  :disabled="field[1].read_only"
                  :class="{ error: $v.model[field[0]].$anyError }"
                  v-model="$v.model[field[0]].$model"
                  v-else-if="['integer', 'string', 'email'].includes(field[1].type)"
                ></b-form-input></b-form-group></template
            ><slot v-bind="{ field, validations: $v }" :name="field[0]"></slot></b-col
        ></template>
      </b-row>
    </template>
    <b-form-checkbox :class="{ error: $v.agree.$anyError }" v-if="agreeMark" v-model="agree">
      <span style="display: contents" class="option__title required-field-star"
        >Я соглашаюсь с<b-button
          target="_blank"
          variant="link"
          style="display: contents"
          href="/policy"
        >
          Пользовательским соглашением</b-button
        >
        и
        <b-button target="_blank" variant="link" style="display: contents" href="/privacy">
          Политикой конфиденциальности</b-button
        ></span
      >
    </b-form-checkbox></b-form
  >
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import parsers from '@main/func/parsers';
import Multiselect from 'vue-multiselect';
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  email,
} from 'vuelidate/lib/validators';
import NestedListFormContainer from '../functional/NestedListFormContainer.vue';
import ScreenshotLoader from './ScreenshotLoader.vue';
import LeftPointedRadioButton from '../elements/buttons/LeftPointedRadioButton.vue';
import LegalStatusRadioGroup from '../elements/radio/LegalStatusRadioGroup.vue';
import SingleFileInput from '../../forms/SingleFileInput.vue';
import ListInput from '../elements/inputs/ListInput.vue';

export default {
  components: {
    NestedListFormContainer,
    ScreenshotLoader,
    LeftPointedRadioButton,
    LegalStatusRadioGroup,
    SingleFileInput,
    ListInput,
  },
  name: 'meta-data-form',
  data: () => ({
    inputTypeMapping: {
      string: 'text',
      integer: 'number',
      email: 'email',
    },
    excludeFieldsDefault: ['id'],
    agree: false,
    harmonicArray: {},
    fieldDisplayCursor: 0,
  }),
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    disableEndDots: {
      type: Boolean,
    },
    endCharacter: {
      type: String,
    },
    fieldDisplayLimit: {
      type: Number,
    },
    labelStyle: {
      type: String,
    },
    metadata: {
      type: Object,
      required: true,
    },
    model: {
      type: [Object, Array],
      required: true,
    },
    grid: {
      type: [Object, Array],
      default: Array,
    },
    excludeFields: {
      type: Array,
      default: Array,
    },
    settings: {
      type: Object,
    },
    conditions: {
      type: Object,
      default: Object,
    },
    overrides: {
      type: Object,
      default: Object,
    },
    disableRequiredStar: {
      type: Boolean,
      default: false,
    },
    agreeMark: { type: Boolean, default: false },
    formGroupStyle: { type: String },
  },
  validations() {
    const val = {};
    this.displayFields.forEach((field) => {
      const item = this.metadata[field[0]];
      const validations = {};
      if (field[0] === 'id') {
        return;
      }
      if (item.required) {
        validations.required = required;
      }
      if (item.type === 'string') {
        validations.minLength = minLength(0);
        if (item.max_length) {
          validations.maxLength = maxLength(item.max_length);
        }
      } else if (item.type === 'integer') {
        validations.minValue = minValue(item.min_value);
        validations.maxValue = maxValue(item.max_value);
      } else if (item.type === 'email') {
        validations.email = email;
      } else if (item.type === 'choice') {
        validations.notNull = (value) => {
          return value !== 0;
        };
      }
      val[field[0]] = validations;
    });
    const validations = { model: val };
    if (this.agreeMark) {
      validations.agree = {
        required,
        notFalse: (value) => {
          return value !== false;
        },
      };
    }
    return validations;
  },
  methods: {
    parseObject(obj) {
      return parsers.djangoRestFrameworkMetadataParser(obj);
    },
  },
  watch: {
    clonedModel: {
      handler(newVal, oldVal) {
        if (!this.fieldDisplayLimit) {
          return;
        }
        Object.keys(this.model).forEach((key) => {
          if (oldVal[key] !== newVal[key]) {
            this.fieldDisplayCursor += 1;
          }
        });
      },
    },
  },
  computed: {
    clonedModel() {
      return JSON.parse(JSON.stringify(this.model));
    },
    currentDisplayFields() {
      return this.displayFields.slice(
        this.fieldDisplayCursor,
        this.fieldDisplayCursor + this.fieldDisplayLimit
      );
    },
    // eslint-disable-next-line vue/return-in-computed-property
    formGrid() {
      const defaultGrid = this.fieldDisplayLimit ? this.currentDisplayFields : this.displayFields;
      const usedItems = [];
      const resultGrid = [];
      for (let i = 0; i < defaultGrid.length; i += 1) {
        const defaultItem = defaultGrid[i];
        if (!usedItems.includes(defaultItem[0])) {
          const sizingItems = this.grid.filter((item) => {
            return item.includes(defaultItem[0]);
          });
          if (sizingItems.length) {
            const gridRow = [];
            for (let k = 0; k < sizingItems[0].length; k += 1) {
              const griditems = defaultGrid.filter((item) => {
                return sizingItems[0][k] === item[0];
              });
              if (griditems.length) {
                gridRow.push(griditems[0]);
              }
            }
            resultGrid.push(gridRow);
            sizingItems[0].forEach((item) => {
              usedItems.push(item);
            });
          } else {
            resultGrid.push([defaultItem]);
          }
        }
      }
      return resultGrid;
    },
    excludedFields() {
      return this.excludeFields.concat(this.excludeFieldsDefault);
    },
    displayFields() {
      return Object.entries(this.metadata)
        .filter((val) => {
          return (
            !this.excludeFields.includes(val[0]) &&
            (!this.conditions || !this.conditions[val[0]] || this.conditions[val[0]].handler())
          );
        })
        .map((field) => {
          return [
            field[0],
            this.overrides[field[0]]
              ? {
                  ...field[1],
                  ...this.overrides[field[0]],
                }
              : field[1],
          ];
        });
    },
  },
  mounted() {
    console.log(this.model);
  },
};
</script>

<style lang="scss" scoped>
.fmt-rd {
  width: 48% !important;
  min-width: 110px !important;
}
::v-deep .custom-range {
  background-color: transparent !important;
}
::v-deep .required__star .col-form-label:after {
  content: '*';
  color: red;
  margin-left: 5px;
}
.required__star::after {
  content: '*';
  color: red;
  margin-left: 5px;
}
::v-deep .col-form-label {
  font-size: 16px !important;
}
</style>
